<template>
  <div class="item" @click="$emit('click')">
    <div class="top">
      <div class="no">
        <img
          src="https://songcw-sit.oss-cn-shanghai.aliyuncs.com/20230628/dEO0yvIALOt7wqdgmQRYn2zrdkDlQfva.png"
          alt=""
        />
        {{ item.bizNo }}
      </div>
      <div class="arrow"></div>
      <slot name="topSuffix" />
    </div>

    <div class="infos">
      <div class="infos-row">
        <div class="ftbd">{{ item.customerName }}</div>
        <div>{{ item.capitalName }}</div>
      </div>
      <div class="infos-row">
        <div>{{ item.productName }}</div>
        <div>
          逾期
          <span class="col_red"
            >{{ item.overdueDays || item.currentOverdueDay }}天</span
          >
        </div>
      </div>
      <div class="infos-row">
        <div>融资余额：{{ columnAmount(item.rentBalance) }}</div>
        <div>拖欠金额：{{ columnAmount(item.arrearsAmount) }}</div>
      </div>
      <div v-if="from == 'urgeChargeRecordManage'" class="infos-row">
        <div class="leading-normal mt-neg_4">催收结果：{{ item.collectionResult ?? "-" }}</div>
        <div class="whitespace-nowrap ml-8">
          催收日期：{{ item.collectionDate ?? "-" }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { columnAmount } from "@/utils/tool";

export default {
  props: {
    item: Object,
    from: String,
  },
  methods: {
    columnAmount,

    //
  },
};
</script>
<style lang="scss" scoped>

.item {
  width: 702px;
  // height: 256px;
  background: #ffffff;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin-bottom: 24px;
  padding: 0 24px 32px;
  overflow: hidden;
  .top {
    height: 32px;
    display: flex;
    justify-content: space-between;
    margin-top: 32px;
    align-items: center;
    .no {
      display: flex;
      align-items: center;
      img {
        height: 32px;
        width: 32px;
        margin-right: 12px;
      }
      height: 32px;
      font-size: 32px;
      font-family: PingFangSC-Semibold, PingFang SC;
      font-weight: 600;
      color: #333333;
      line-height: 32px;
    }

    /* .arrow {
          &::after {
          }
        } */
  }

  .infos {
    margin-top: 32px;
    .infos-row {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
      font-size: 28px;
      // height: 28px;
      line-height: 28px;
      &:last-child {
        margin-bottom: 0;
      }
      .ftbd {
        font-weight: bold;
      }
      .col_red {
        color: #ff5252;
      }
    }
  }
}

</style>